export const cultures = {
  WHEAT: 'wheat',
  RAPESEED: 'rapeseed',
  SUNFLOWER: 'sunflower',
  BARLEY: 'barley',
  CORN: 'corn',
  PEAS: 'peas',
};

export const availableCultures = [
  cultures.WHEAT,
  cultures.RAPESEED,
  cultures.SUNFLOWER,
  cultures.BARLEY,
  cultures.CORN,
];

export const types = {
  HARVEST: 'harvest',
  INTERVENTION: 'intervention',
  SEEDLING: 'seedling',
};

export const availableTypes = [types.HARVEST];
