import { useQuery } from '@tanstack/react-query';
import type { BarleyCategory, WheatCategory } from './api';
import {
  fetchBarleyObservations,
  fetchCornObservations,
  fetchObservation,
  fetchObservations,
  fetchRapeseedObservations,
  fetchSunflowerObservations,
  fetchWheatObservations,
} from './api';
import { useState } from 'react';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import { times } from '../times';

// TODO: [REFACTO] make it truly dynamic and move it to its own thing
function useHarvestYear() {
  const [year] = useState(getCurrentHarvestYear());
  return { year };
}

export function useObservation(id: string) {
  return useQuery({
    queryKey: ['observations', id],
    queryFn: async () => fetchObservation(id),
    staleTime: times.thirtyMinutes,
    gcTime: times.oneHour,
  });
}

export function useObservations(limit: number = 10) {
  const { year } = useHarvestYear();

  return useQuery({
    queryKey: ['observations', { limit }],
    queryFn: async () => fetchObservations(year, limit),
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useRapeseedObservations() {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'rapeseed', { year }],
    queryFn: async () => {
      return fetchRapeseedObservations(year);
    },
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useCornObservations() {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'corn', { year }],
    queryFn: async () => {
      return fetchCornObservations(year);
    },
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useWheatObservations(category?: WheatCategory) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'wheat', { category, year }],
    queryFn: async () => {
      return fetchWheatObservations(year, category);
    },
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useBarleyObservations(category?: BarleyCategory) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'barley', { category, year }],
    queryFn: async () => {
      return fetchBarleyObservations(year, category);
    },
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useSunflowerObservations() {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'sunflower', { year }],
    queryFn: async () => {
      return fetchSunflowerObservations(year);
    },
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}
