import * as L from 'leaflet';

const ObservationIcon = L.Icon.extend({
  options: {
    iconSize: [32, 37.5],
  },
});

// @ts-ignore
export const rapeseed = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/rapeseed.png',
});

// @ts-ignore
export const rapeseedWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/rapeseed-picture.png',
});

// @ts-ignore
export const corn = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/corn.png',
});

// @ts-ignore
export const cornWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/corn-picture.png',
});

// @ts-ignore
export const feedWheat = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/feed-wheat.png',
});

// @ts-ignore
export const feedWheatWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/feed-wheat-picture.png',
});

// @ts-ignore
export const durumWheat = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/durum-wheat.png',
});

// @ts-ignore
export const durumWheatWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/durum-wheat-picture.png',
});

// @ts-ignore
export const winterBarley = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/winter-barley.png',
});

// @ts-ignore
export const winterBarleyWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/winter-barley-picture.png',
});

// @ts-ignore
export const springBarley = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/spring-barley.png',
});

// @ts-ignore
export const springBarleyWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/spring-barley-picture.png',
});

// @ts-ignore
export const sunflower = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/sunflower.png',
});

// @ts-ignore
export const sunflowerWithPicture = new ObservationIcon({
  iconUrl:
    'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/sunflower-picture.png',
});
