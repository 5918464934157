import { useCookies } from 'react-cookie';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import { useCallback } from 'react';

export function useAuthentication() {
  const year = getCurrentHarvestYear();
  const key = 'authentication-' + year;
  const [cookies, setCookie] = useCookies([key]);

  const authenticate = useCallback(() => {
    setCookie(key, 'true', {
      path: '/',
      maxAge: 31536000,
    });
  }, [key, setCookie]);

  const isAuthenticated = cookies[key] === 'true';

  return {
    authenticate,
    isAuthenticated,
  };
}

export function useEmail() {
  const [cookies, setCookie] = useCookies(['email']);

  function changeEmail(newEmail: null | string) {
    setCookie('email', newEmail);
  }

  function getEmail() {
    return cookies['email'];
  }

  return {
    changeEmail,
    getEmail,
  };
}

export function usePhone() {
  const [cookies, setCookie] = useCookies(['phone']);

  function changePhone(newPhone: null | string) {
    setCookie('phone', newPhone);
  }

  function getPhone() {
    return cookies['phone'];
  }

  return {
    changePhone,
    getPhone,
  };
}
