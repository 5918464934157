import React, { PropsWithChildren, useCallback } from 'react';
import cn from 'classnames';

import * as Dropdown from '../../components/dropdown';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Culture, Layers, useLayersConfig } from '../../modules/layers';

type Filter = {
  culture: Culture | null;
  label: string;
  image?: string;
  alt?: string;
};

const filters = (t: TFunction) =>
  [
    {
      culture: null,
      label: t('filters.items.all'),
    },
    {
      culture: 'colza',
      label: t('filters.items.rapeseed'),
      image:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/rapeseed.png',
      alt: 'rapeseed marker',
    },
    {
      culture: 'ble_tendre',
      label: t('filters.items.feed_wheat'),
      image:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/feed-wheat.png',
      alt: 'feed wheat marker',
    },
    {
      culture: 'ble_dur',
      label: t('filters.items.durum_wheat'),
      image:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/durum-wheat.png',
      alt: 'durum wheat marker',
    },
    {
      culture: 'orge_hiver',
      label: t('filters.items.winter_barley'),
      image:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/winter-barley.png',
      alt: 'winter barley marker',
    },
    {
      culture: 'orge_printemps',
      label: t('filters.items.spring_barley'),
      image:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/spring-barley.png',
      alt: 'spring barley marker',
    },
  ] satisfies Filter[];

export function Filters() {
  const { t } = useTranslation();
  const { layers, change } = useLayersConfig();
  const cultureActive = layers.observations;

  const isCultureActive = useCallback(
    (culture: null | Culture) => {
      return cultureActive === 'all' || cultureActive === culture;
    },
    [cultureActive]
  );

  const onCultureChange = (culture: null | Culture) => {
    change({
      observations: culture ?? 'all',
      // On force la couche de prévision pour éviter la confusion lors de la lecture de la carte
      previsions: getCorrespondingPrevisionLayer(layers.previsions, culture),
    });
  };

  const items = filters(t).map(({ culture, label, image, alt }) => (
    <Dropdown.SelectableItem
      key={culture ?? 'all'}
      onClick={() => onCultureChange(culture as Culture)}
      isActive={isCultureActive(culture as Culture)}
      label={label}
      image={
        image ?? (
          <div className="tw-inline-block tw-h-5 tw-w-5 lg:tw-h-3 lg:tw-w-3" />
        )
      }
      alt={alt}
    />
  ));

  return (
    <>
      <div className="tw-hidden lg:tw-block">
        <HorizontalList>{items}</HorizontalList>
      </div>
      <div className="tw-block lg:tw-hidden">
        <Dropdown.Dropdown>
          <Dropdown.Button>{t('filters.menu_title')}</Dropdown.Button>
          <Dropdown.List>{items}</Dropdown.List>
        </Dropdown.Dropdown>
      </div>
    </>
  );
}

function getCorrespondingPrevisionLayer(
  layer: Layers['previsions'],
  culture: null | Culture
): Layers['previsions'] {
  // l'user a cliqué sur "Tous"
  if (culture === null) {
    return 'hide';
  }

  if (layer.startsWith('rendement')) {
    return `rendement_${culture}`;
  }

  if (layer.startsWith('evolution_rendement')) {
    return `evolution_rendement_${culture}`;
  }

  if (layer.startsWith('proteine')) {
    return `proteine_${culture}`;
  }

  if (layer.startsWith('poids_specifique')) {
    return `poids_specifique_${culture}`;
  }

  if (layer.startsWith('humidite')) {
    return `humidite_${culture}`;
  }

  return 'hide';
}

function HorizontalList({ children }: PropsWithChildren) {
  return (
    <ul
      className={cn(
        'tw-flex tw-items-center tw-content-between tw-gap-x-1 tw-m-0 tw-list-none tw-join',
        'tw-border tw-border-solid tw-border-base-300 tw-bg-white'
      )}
    >
      {children}
    </ul>
  );
}
