import * as Api from '../../api/common';
import {
  Coordinates,
  HydraCollection,
  HydraElement,
  HydraResponse,
} from '../../api/common';
import { HarvestYear } from '../../configuration/seasons';
import { CountryCode } from '../../config';

// TODO [REFACTORING] better types for both input and output DTOs

type Iri = string;

// LIST
export type MinimalObservation =
  | HydraElement<{
      '@type':
        | 'RapeseedObservation'
        | 'CornObservation'
        | 'SunflowerObservation';
      id: string;
      coordinates: Coordinates;
      place: string;
      createdAt: string;
      country: CountryCode;
      yield: number;
      yieldNotation: number;
      cultivationMethod: string;
      category: undefined;
      picture: boolean;
    }>
  | HydraElement<{
      '@type': 'WheatObservation';
      id: string;
      coordinates: Coordinates;
      category: WheatCategory;
      place: string;
      createdAt: string;
      country: CountryCode;
      yield: number;
      yieldNotation: number;
      cultivationMethod: string;
      picture: boolean;
    }>
  | HydraElement<{
      '@type': 'BarleyObservation';
      id: string;
      coordinates: Coordinates;
      category: BarleyCategory;
      place: string;
      createdAt: string;
      country: CountryCode;
      yield: number;
      yieldNotation: number;
      cultivationMethod: string;
      picture: boolean;
    }>;

export type ObservationDetails =
  | CornObservationDetails
  | WheatObservationDetails
  | BarleyObservationDetails
  | RapeseedObservationDetails;

// TODO: move this to a common place
export type MediaObject = HydraResponse<{
  id: string;
  contentUrl: string;
}>;

// DETAILS
type CommonObservationDetails = {
  id: string;
  variety: null | string;
  yield: null | number;
  humidity: any;
  yieldNotation: any;
  nitrogenQuantityUsed: any;
  nitrogenProductUsed: any;
  specificWeight: any;
  protein: any;
  fallingNumber: any;
  comment: any;
  cultivationMethod: any;
  targetPrice: any;
  place: any;
  coordinates: Coordinates;
  createdAt: null | number;
  cultureName: null | string;
  image: null | MediaObject;
  soldVolume: null | string;
  sowingWeek: any;
  combineHarvester: CombineHarvester;
  category: null | BarleyCategory | WheatCategory; // more specific types are defined below for each crop
  grainsRetainedAfterScreening: null;
  presenceSeptoriose: string | null;
  presenceFusariose: string | null;
};

type CornObservationDetails = CommonObservationDetails & {
  category: undefined;
};

export type WheatCategory = 'feed' | 'durum';
type WheatObservationDetails = CommonObservationDetails & {
  category: null | WheatCategory;
};

export type BarleyCategory = 'winter' | 'spring';
type BarleyObservationDetails = CommonObservationDetails & {
  category: null | BarleyCategory;
  grainsRetainedAfterScreening?: number;
};

type RapeseedObservationDetails = CommonObservationDetails & {
  category: undefined;
};

type CombineHarvester = {
  name?: string;
  brand?: string;
  model?: string;
  yearOfPurchase?: number;
  year?: number;
  image?: Iri;
};

export async function fetchObservation(id: string) {
  if (!id) {
    return null;
  }

  return (await Api.get(`/observations/${id}`)) as Promise<
    HydraResponse<ObservationDetails>
  >;
}

export async function fetchObservations(year: HarvestYear, limit: number) {
  return (await Api.get(`/observations`, {
    season: year,
    limit,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function fetchRapeseedObservations(year: HarvestYear) {
  return (await Api.get(`/rapeseed-observations`, {
    season: year,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function createRapeseedObservation(payload: object) {
  console.info('[App] Sending data (rapeseed)', payload);

  return (await Api.post(`/rapeseed-observations`, payload, {
    credentials: 'include',
  })) as Promise<HydraElement<RapeseedObservationDetails>>;
}

export async function fetchCornObservations(year: HarvestYear) {
  return (await Api.get(`/corn-observations`, {
    season: year,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function createCornObservation(payload: object) {
  console.info('[App] Sending data (corn)', payload);

  return (await Api.post(`/corn-observations`, payload, {
    credentials: 'include',
  })) as Promise<HydraElement<CornObservationDetails>>;
}

export async function fetchWheatObservations(
  year: HarvestYear,
  category?: WheatCategory
) {
  return (await Api.get(`/wheat-observations`, {
    season: year,
    category: category || undefined,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function createWheatObservation(payload: object) {
  console.info('[App] Sending data (wheat)', payload);

  return (await Api.post(`/wheat-observations`, payload, {
    credentials: 'include',
  })) as Promise<HydraElement<WheatObservationDetails>>;
}

export async function fetchBarleyObservations(
  year: HarvestYear,
  category?: BarleyCategory
) {
  return (await Api.get(`/barley-observations`, {
    season: year,
    category: category || undefined,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function createBarleyObservation(payload: object) {
  console.info('[App] Sending data (barley)', payload);

  return (await Api.post(`/barley-observations`, payload, {
    credentials: 'include',
  })) as Promise<HydraElement<BarleyObservationDetails>>;
}

export async function fetchSunflowerObservations(year: HarvestYear) {
  return (await Api.get(`/sunflower-observations`, {
    season: year,
  })) as Promise<HydraCollection<MinimalObservation>>;
}

export async function createSunflowerObservation(payload: object) {
  console.info('[App] Sending data (sunflower)', payload);

  return (await Api.post(`/sunflower-observations`, payload, {
    credentials: 'include',
  })) as Promise<HydraElement<ObservationDetails>>;
}
