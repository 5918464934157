import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Modal from '../modals';
import * as Dropdown from '../../components/dropdown';
import { MapButton } from '../buttons';

import combineHarvesterIcon from '../../images/icons/combine-harvester.png';

export function EquipmentRankingButton() {
  const { t } = useTranslation();
  const { open } = Modal.useModal();

  return (
    <MapButton
      className="tw-flex tw-items-center tw-justify-center"
      onClick={() => open(Modal.Id.EquipmentRanking)}
    >
      <img
        src={combineHarvesterIcon}
        alt=""
        className="tw-max-h-6 tw-max-w-6"
      />
      <span>{t('ranking_combine_harvester.button.label')}</span>
    </MapButton>
  );
}

export function EquipmentRankingMenuItem() {
  const { t } = useTranslation();
  const { open } = Modal.useModal();

  return (
    <Dropdown.Item onClick={() => open(Modal.Id.EquipmentRanking)}>
      <img
        src={combineHarvesterIcon}
        alt=""
        className="tw-max-h-6 tw-max-w-6"
      />
      <span>{t('ranking_combine_harvester.button.label')}</span>
    </Dropdown.Item>
  );
}
