import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation, withTranslation } from 'react-i18next';
import NavigationBar from '../../../NavigationBar';
import validate from './validate';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './styles.module.scss';
import FieldInput from '../../../../../common/form/FieldInput';
import {
  CultivationMethodField,
  GrainsRetainedAfterScreeningField,
  HumidityField,
  NitrogenProductField,
  NitrogenQuantityField,
  PresenceFusarioseField,
  PresenceSeptorioseField,
  SpecificWeightField,
  TargetPriceField,
  VarietyField,
  VolumeSoldField,
  YieldField,
  YieldRatingField,
  ZipcodeField,
} from '../../fields';
import SubSectionTitle from '../../../SubSectionTitle';
import cn from 'classnames';

const BarleyObservationForm = ({ handleSubmit, previousStep, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <section>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn(styles.form, 'tw-flex tw-flex-col tw-gap-y-4')}
      >
        <Row>
          <Col xs={12}>
            <SubSectionTitle
              title={t(
                'modale_creation_observation.observation.sub_section.base'
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <ZipcodeField />
          </Col>
          <Col xs={12} md={6}>
            <VarietyField />
          </Col>
          <Col xs={12} md={6}>
            <YieldField />
          </Col>
          <Col xs={12} md={6}>
            <YieldRatingField />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SubSectionTitle
              title={t(
                'modale_creation_observation.observation.sub_section.qualite'
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <SpecificWeightField />
          </Col>
          <Col xs={12} md={6}>
            <GrainsRetainedAfterScreeningField />
          </Col>
          <Col xs={12} md={6}>
            <HumidityField />
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12} md={9}>
                <PresenceSeptorioseField />
              </Col>
              <Col xs={12} md={9}>
                <PresenceFusarioseField />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SubSectionTitle
              title={t(
                'modale_creation_observation.observation.sub_section.pratiques'
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <CultivationMethodField />
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12} md={6}>
                <NitrogenProductField />
              </Col>
              <Col xs={12} md={6}>
                <NitrogenQuantityField />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SubSectionTitle
              title={t(
                'modale_creation_observation.observation.sub_section.commercial'
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <TargetPriceField />
          </Col>
          <Col xs={12} md={6}>
            <VolumeSoldField />
          </Col>
          <Field name="category" component={FieldInput} type="hidden" />
        </Row>

        <div className={styles.navigationWrapper}>
          <NavigationBar
            previousStep={previousStep}
            shouldSubmit={true}
            submitLabel={t('common.next')}
          />
        </div>
      </form>
    </section>
  );
};

export default withTranslation()(
  reduxForm({
    form: 'observation',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(BarleyObservationForm)
);
