import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';

import './tailwind.css';
import './App.css';
import './styles/import.scss';

import { ModalProvider } from './modules/modal/Modal';
import TopBar from './components/nav';
import { GeolocateButton } from './components/buttons/GeolocateButton';
import PartnerDetailsModal from './components/modals/PartnerDetailsModal';
import { SensorDetailsModal } from './components/modals/SensorDetailsModal';
import ObservationDetailsModal from './components/modals/ObservationDetailsModal';
import ObservationCreationModal from './components/modals/ObservationCreationModal';
import ToastList from './components/common/toast/ToastList';
import {
  FloatingCell,
  FloatingLayer,
  FloatingRow,
} from './components/layers/floating';
import { MapLayer } from './components/layers/map';
import { AddObservationButton, Filters } from './components/observation';
import PartnersMarkerList from './components/partner/PartnersMarkerList';
import { SensorsPane } from './modules/sensors';
import { ConfigProvider, useConfig } from './modules/config';
import { ErrorBoundary } from '@sentry/react';
import { AboutUsModal, FaqModal } from './modules/partners';
import { ObservationsPane } from './modules/observations/map';
import {
  DepartmentsRankingButton,
  EquipmentRankingButton,
  RankingsMenu,
} from './components/rankings';
import { useTranslation } from 'react-i18next';
import { LayersConfigProvider } from './modules/layers';
import {
  PrevisionsLegendeCard,
  PrevisionsLegendeModalButton,
  PrevisionsMenu,
  PrevisionsPane,
} from './modules/previsions';
import { PrevisionsGlobalesButton } from './modules/previsions/global';
import { CadeauButton, CadeauModal } from './modules/cadeau';
import { ResultatsModal, ResultatsModalAutoOpener } from './modules/resultats';
import { CreationContactModal } from './modules/contacts';
import * as Modal from './components/modals';
import { FaqModalAutoOpener } from './modules/partners/about';

const EquipmentRankingModal = lazy(() =>
  import('./components/rankings/equipment.lazy').then((m) => ({
    default: m.EquipmentRankingModal,
  }))
);

const DepartmentsRankingModal = lazy(() =>
  import('./components/rankings/departments.lazy').then((m) => ({
    default: m.DepartmentsRankingModal,
  }))
);

const TimelineModal = lazy(() =>
  import('./modules/timeline/modal.lazy').then((m) => ({
    default: m.TimelineModal,
  }))
);

const PrevisionsGlobalesModal = lazy(() =>
  import('./modules/previsions/global.lazy').then((m) => ({
    default: m.PrevisionsGlobalesModal,
  }))
);

const PrevisionsLegendeModal = lazy(() =>
  import('./modules/previsions/floating.lazy').then((m) => ({
    default: m.PrevisionsLegendeModal,
  }))
);

const LastObservationsModal = lazy(
  () => import('./components/modals/LastObservationsModal')
);
export default function App() {
  return (
    <ConfigProvider>
      <Modal.Provider>
        <LayersConfigProvider>
          <Meta />
          {/*
            START Modals --
            Should not be included in stacking contexts,otherwise the polyfills won't work, see https://github.com/GoogleChrome/dialog-polyfill#stacking-context
        */}
          <ErrorBoundary>
            <Suspense>
              <EquipmentRankingModal />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense>
              <DepartmentsRankingModal />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense>
              <TimelineModal />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense>
              <PrevisionsGlobalesModal />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense>
              <PrevisionsLegendeModal />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <AboutUsModal />
          </ErrorBoundary>

          <ErrorBoundary>
            <FaqModal />
          </ErrorBoundary>

          <ErrorBoundary>
            <CadeauModal />
          </ErrorBoundary>

          <ErrorBoundary>
            <ResultatsModal />
          </ErrorBoundary>

          <ErrorBoundary>
            <CreationContactModal />
          </ErrorBoundary>
          {/* END Modals */}

          <ModalProvider>
            <TopBar />
            <ToastList />
            <ErrorBoundary>
              <SensorDetailsModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <PartnerDetailsModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <ObservationDetailsModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <ObservationCreationModal />
            </ErrorBoundary>
            <ErrorBoundary>
              <Suspense>
                <LastObservationsModal />
              </Suspense>
            </ErrorBoundary>

            <MapLayer>
              <ErrorBoundary>
                <SensorsPane />
              </ErrorBoundary>
              <ErrorBoundary>
                <PartnersMarkerList />
              </ErrorBoundary>

              <ErrorBoundary>
                <Suspense>
                  <PrevisionsPane />
                </Suspense>
              </ErrorBoundary>

              <ObservationsPane />

              <FloatingLayer>
                <FloatingRow className="tw-flex-wrap">
                  <FloatingCell className="tw-block lg:tw-hidden">
                    <ErrorBoundary>
                      <PrevisionsMenu />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell className="tw-block lg:tw-hidden">
                    <ErrorBoundary>
                      <PrevisionsGlobalesButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell>
                    <ErrorBoundary>
                      <Filters />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell className="tw-block lg:tw-hidden">
                    <ErrorBoundary>
                      <RankingsMenu />
                    </ErrorBoundary>
                  </FloatingCell>

                  {/* Forces new row if size < SM */}
                  <div className="tw-w-full sm:tw-hidden" />

                  <FloatingCell>
                    <ErrorBoundary>
                      <CadeauButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell className="lg:tw-ml-auto">
                    <ErrorBoundary>
                      <GeolocateButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell className="tw-block lg:tw-hidden">
                    <ErrorBoundary>
                      <PrevisionsLegendeModalButton />
                    </ErrorBoundary>
                  </FloatingCell>
                </FloatingRow>

                {/* Rankings and forecasts Buttons - Desktop only */}
                <FloatingRow className="tw-flex-wrap tw-hidden lg:tw-flex">
                  <FloatingCell>
                    <ErrorBoundary>
                      <EquipmentRankingButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell>
                    <ErrorBoundary>
                      <DepartmentsRankingButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell>
                    <ErrorBoundary>
                      <PrevisionsGlobalesButton />
                    </ErrorBoundary>
                  </FloatingCell>

                  <FloatingCell>
                    <ErrorBoundary>
                      <PrevisionsMenu />
                    </ErrorBoundary>
                  </FloatingCell>

                  <div className="tw-w-full" />

                  <FloatingCell>
                    <ErrorBoundary>
                      <PrevisionsLegendeCard />
                    </ErrorBoundary>
                  </FloatingCell>
                </FloatingRow>

                <FloatingRow className="tw-mt-auto tw-order-last tw-pb-20 lg:tw-pb-4">
                  <FloatingCell className="tw-mx-auto">
                    <AddObservationButton />
                  </FloatingCell>
                </FloatingRow>
              </FloatingLayer>
            </MapLayer>

            <FaqModalAutoOpener />
            <ResultatsModalAutoOpener />
          </ModalProvider>
        </LayersConfigProvider>
      </Modal.Provider>
    </ConfigProvider>
  );
}

function Meta() {
  // TODO: setup proper SSR with meta tag support like in Next.js or Remix.run
  const config = useConfig();
  const { t } = useTranslation();

  return (
    <Helmet>
      <html lang={config.lang} />
      <title>{t('meta.title')}</title>
      <meta name="description" content={t('meta.description') ?? undefined} />
      <link
        rel="icon"
        href={`/${config.country}/icon-16x16.png`}
        type="image/png"
        sizes="16x16"
      />
      <link
        rel="icon"
        href={`/${config.country}/icon-32x32.png`}
        type="image/png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href={`/${config.country}/icon-96x96.png`}
        type="image/png"
        sizes="96x96"
      />
      <link
        rel="icon"
        href={`/${config.country}/icon-192x192.png`}
        type="image/png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href={`/${config.country}/icon-192x192.png`}
      />
      <link rel="manifest" href={`/${config.country}/manifest.json`} />
    </Helmet>
  );
}
