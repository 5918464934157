import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Dropdown from '../../components/dropdown';
import { DepartmentsRankingMenuItem } from './departments';
import { EquipmentRankingMenuItem } from './equipment';
export { EquipmentRankingButton } from './equipment';
export { DepartmentsRankingButton } from './departments';

type RankingsMenuProps = {
  className?: string;
};

export function RankingsMenu({ className }: RankingsMenuProps) {
  const { t } = useTranslation();

  return (
    <Dropdown.Dropdown className={className}>
      <Dropdown.Button>
        <span>{t('rankings.menu.label')}</span>
      </Dropdown.Button>

      <Dropdown.List>
        <EquipmentRankingMenuItem />
        <DepartmentsRankingMenuItem />
      </Dropdown.List>
    </Dropdown.Dropdown>
  );
}
