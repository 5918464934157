import React from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import NavigationBar from '../../NavigationBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionTitle from '../../SectionTitle';
import styles from './styles.module.scss';
import validate from './validate';
import { useTranslation } from 'react-i18next';
import {
  CombineHarvesterBrandField,
  CombineHarvesterBreakdownDurationField,
  CombineHarvesterBreakdownField,
  CombineHarvesterModelField,
  CombineHarvesterYearOfPurchaseField,
} from '../fields';
import { connect } from 'react-redux';

const selector = formValueSelector('observation');

function EquipmentForm({
  handleSubmit,
  previousStep,
  onSubmit,
  combineHarvester,
}) {
  const { t } = useTranslation();
  return (
    <section>
      <SectionTitle
        title={t('modale_creation_observation.equipment.section_label')}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Row>
          <Col xs={12} md={12}>
            <CombineHarvesterBrandField />
          </Col>
          <Col xs={12} md={6}>
            <CombineHarvesterModelField />
          </Col>
          <Col xs={12} md={6}>
            <CombineHarvesterYearOfPurchaseField />
          </Col>
          <Col xs={12} md={6}>
            <CombineHarvesterBreakdownField />
          </Col>
          {combineHarvester?.hadBreakdown === true && (
            <Col xs={12} md={6}>
              <CombineHarvesterBreakdownDurationField />
            </Col>
          )}
        </Row>
        <div className={styles.navigationWrapper}>
          <NavigationBar
            previousStep={previousStep}
            shouldSubmit={true}
            canSkip={true}
            submitLabel={t('common.next')}
          />
        </div>
      </form>
    </section>
  );
}

const ConnectedForm = connect((state) => ({
  combineHarvester: selector(state, 'combineHarvester'),
}))(EquipmentForm);

export default reduxForm({
  form: 'observation',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(ConnectedForm);
