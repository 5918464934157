import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Modal from '../../components/modals';
import { Id, useModal } from '../../components/modals';
import { useConfig } from '../config';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import cn from 'classnames';
import { IoIosStats } from 'react-icons/io';
import { useAuthentication } from '../contacts';
import { NavButton } from '../../components/nav/buttons';
import { HashMatcher } from '../routing';

export function ResultatsButton() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthentication();
  const { open } = Modal.useModal();

  const handleClick = useCallback(() => {
    if (isAuthenticated) {
      open(Modal.Id.Resultats);
      return;
    }

    open(Modal.Id.CreationContact, Modal.Id.Resultats);
  }, [isAuthenticated, open]);

  return (
    <NavButton
      onClick={handleClick}
      title={t('resultats.bouton.titre') ?? ''}
      icon={<IoIosStats />}
    >
      {t('resultats.bouton.label')}
    </NavButton>
  );
}

export function ResultatsModal() {
  const { t } = useTranslation();
  const { resultats } = useConfig();
  const { isAuthenticated } = useAuthentication();

  if (!resultats.enabled) {
    return null;
  }

  const annee = getCurrentHarvestYear();

  return (
    <Modal.Dialog id={Modal.Id.Resultats}>
      <Modal.Box
        className={cn('tw-max-w-6xl tw-h-full', 'lg:tw-h-5/6 lg:tw-max-h-full')}
      >
        <form method="dialog">
          <Modal.CloseButton />
        </form>
        <h3 className="tw-font-bold tw-text-lg tw-text-center tw-w-full">
          {t('resultats.modale.titre', {
            annee: getCurrentHarvestYear(),
          })}
        </h3>

        {isAuthenticated && (
          <iframe
            title={
              t('resultats.modale.titre', {
                annee,
              }) ?? undefined
            }
            className="tw-w-full tw-h-full"
            src={resultats.iframeUrl}
            loading={'lazy'}
          />
        )}

        {!isAuthenticated && (
          <p>
            {t('resultats.modale.fallback', {
              annee,
            })}
          </p>
        )}
      </Modal.Box>
      <Modal.Overlay />
    </Modal.Dialog>
  );
}

export function ResultatsModalAutoOpener() {
  const { open } = useModal();
  const { isAuthenticated } = useAuthentication();
  return (
    <HashMatcher
      hash="#resultats"
      onMatch={() => {
        if (isAuthenticated) {
          open(Id.Resultats);
          return;
        }

        open(Id.CreationContact, Id.Resultats);
      }}
    />
  );
}
