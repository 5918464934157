export function getCurrentHarvestYear(): HarvestYear {
  const params = new URLSearchParams(window.location.search);
  const now = new Date();
  const isAfterJune = now.getMonth() >= 5;
  const harvestYear = isAfterJune ? now.getFullYear() : now.getFullYear() - 1;

  return parseInt(
    params.get('year') ??
      params.get('annee') ??
      harvestYear.toString() ??
      '2024',
    10
  );
}

export type HarvestYear = string | number;
