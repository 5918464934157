// MyContext.tsx
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { getConfig } from '../../config';
import type { Config } from '../../config';

// Create the context
const ConfigContext = createContext<Config | null>(null);

// Create the provider component
export function ConfigProvider({
  children,
  config,
}: PropsWithChildren<{ config?: Config }>) {
  // TODO: remove hardcoding

  return (
    <ConfigContext.Provider
      value={{
        ...getConfig(),
        ...(config ?? {}),
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

// TODO: [REFACTO] Rename this to useConfiguration
export function useConfig(): Config {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context;
}
