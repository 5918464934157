import React from 'react';
import { useTranslation } from 'react-i18next';

import { AboutUsButton } from '../../modules/partners';
import { ObservationsWithinBoxButton, WhatsAppButton } from './buttons';
import { useConfig } from '../../modules/config';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { FaqButton } from '../../modules/partners/about';
import { TimelineButton } from '../../modules/timeline';
import { ErrorBoundary } from '@sentry/react';
import { ResultatsButton } from '../../modules/resultats';

// TODO: [REFACTO] make this a collapsible menu in responsible mode
function TopBar() {
  const { t } = useTranslation();
  const config = useConfig();
  return (
    <div
      className={classNames(styles.wrapper, 'tw-flex tw-items-center tw-gap-2')}
    >
      <img
        src={config.logos.horizontal}
        alt={t('common.website.alt_logo')}
        className={styles.logo}
      />
      <img
        src={config.logos.squareWhite}
        alt={t('common.website.alt_logo')}
        className={styles.logoResponsive}
      />

      <div className={classNames(styles.buttons, 'tw-flex tw-gap-x-2')}>
        <ErrorBoundary>
          <TimelineButton />
        </ErrorBoundary>
        <ErrorBoundary>
          {config.resultats.enabled && <ResultatsButton />}
        </ErrorBoundary>
        <ErrorBoundary>
          {config.whatsApp.enabled && (
            <WhatsAppButton url={config.whatsApp.url} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          <ObservationsWithinBoxButton />
        </ErrorBoundary>
        <ErrorBoundary>{config.tos.faqEnabled && <FaqButton />}</ErrorBoundary>
        <ErrorBoundary>
          <AboutUsButton />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default TopBar;
