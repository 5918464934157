import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import { FaBookOpen } from 'react-icons/fa';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useAuthentication } from '../../modules/contacts';
import { useModal } from '../../modules/modal/Modal';
import * as Modal from '../../components/modals';

import {
  closeModal,
  openModal,
} from '../../actions/ui/modal/observationsWithinBox';

export function WhatsAppButton({ url }: { url?: string }) {
  const { t } = useTranslation();

  if (!url) {
    return null;
  }

  return (
    <NavLink id="whatsapp" url={url} icon={<FaWhatsapp />}>
      <span className="tw-hidden 2xl:tw-inline-block">
        {t('top_bar.whats_app.label')}
      </span>
      <span className="tw-inline-block 2xl:tw-hidden">
        {t('top_bar.whats_app.label_short')}
      </span>
    </NavLink>
  );
}

export function TheFarmingForumButton({ url }: { url?: string }) {
  const { t } = useTranslation();

  if (!url) {
    return null;
  }

  return (
    <NavLink
      id="the-farming-forum"
      url={url}
      icon={
        <img
          alt="The Farming Forum's thread about Harvest-Live"
          src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689184109/moisson-live/partners/TheFarmingForum-square.svg"
          className="tw-h-5 tw-w-5"
        />
      }
    >
      <span className="tw-hidden 2xl:tw-inline-block">
        {t('top_bar.the_farming_forum.label')}
      </span>
      <span className="tw-inline-block 2xl:tw-hidden">
        {t('top_bar.the_farming_forum.label_short')}
      </span>
    </NavLink>
  );
}

export function ObservationsWithinBoxButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthentication();
  const { currentModal, setCurrentModal } = useModal();
  const name = 'observations';
  const { open } = Modal.useModal();

  // TODO: [REFACTO] move all this to the new dialog system
  useEffect(() => {
    if (currentModal !== name) {
      dispatch(closeModal());
    }
  }, [currentModal, dispatch]);

  return (
    <NavButton
      id="dernieres-observations"
      icon={<FaBookOpen />}
      title={t('dernieres_observations_dans_zone.button.title')}
      onClick={() => {
        if (isAuthenticated) {
          setCurrentModal(name);
          dispatch(openModal());
        } else {
          open(Modal.Id.CreationContact);
        }
      }}
    >
      {t('dernieres_observations_dans_zone.button.label')}
    </NavButton>
  );
}

type NavLinkProps = PropsWithChildren<{
  id?: string;
  url?: string;
  icon?: ReactNode;
  target?: string;
}>;

function NavLink({ id, url, icon, children, target }: NavLinkProps) {
  return (
    <a
      id={id}
      href={url}
      target={target || '_blank'}
      rel="noopener noreferrer"
      className={classNames(
        'btn btn-darken-primary',
        '!tw-flex !tw-items-center tw-gap-2 tw-h-10'
      )}
    >
      {icon}
      <div className={classNames(styles.label, 'tw-hidden lg:tw-block')}>
        {children}
      </div>
    </a>
  );
}

type NavButtonProps = PropsWithChildren<{
  id?: string;
  icon?: ReactNode;
  title?: string | null;
  onClick?: () => void;
  className?: string;
}>;

export function NavButton({
  id,
  icon,
  children,
  title,
  onClick,
  className,
}: NavButtonProps) {
  return (
    <button
      id={id}
      title={title || undefined}
      rel="noopener noreferrer"
      className={classNames(
        'btn btn-darken-primary',
        '!tw-flex !tw-items-center tw-gap-2 tw-h-10',
        className
      )}
      onClick={onClick}
    >
      {icon}
      <div className={classNames(styles.label, 'tw-hidden lg:tw-block')}>
        {children}
      </div>
    </button>
  );
}
